
.backimage {
    min-height: 500px;
    padding: 24px;
    background: #fff;
    background-image: url('../../contains/l2.png');
    background-attachment: fixed;
    /* 图片可以保有其原有的尺寸，或者拉伸到新的尺寸，或者在保持其原有比例的同时缩放到元素的可用空间的尺寸。 */
    background-size: cover;
  }

  .back2image {
    min-height: 700px;
    max-height: 100%;
    max-width: 100%;
    padding: 24px;
    background: #fff;
    background-image: url('../../contains/black3.png');
    background-attachment: fixed;
    /* 图片可以保有其原有的尺寸，或者拉伸到新的尺寸，或者在保持其原有比例的同时缩放到元素的可用空间的尺寸。 */
    background-size: cover;
  }